<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-6 d-flex align-items-stretch mb-3">
                <div class="row flex-grow">
                    <div class="col-12">
                        <div class="text-left">
                            <b-btn v-on:click="resetForm" variant="primary">
                                {{ $t('add-category-btn') }}
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>
            <b-modal id="modalmd" ref="vuemodal" :title="title" size="md" hide-footer>
                <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
                    <div class="row">
                    <div class="col-md-6">
                    <b-form-group :label="$t('name*')" label-for="name">
                        <b-form-input type="text" id="name" name="name" autocomplete="new-password" :placeholder="$t('name-placeholder')" v-model="$v.form.name.$model" :state="$v.form.name.$dirty ? !$v.form.name.$error : null" aria-describedby="input-1-live-feedback">
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                            <!-- <multiselect v-model="selectedUser" :options="users" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Users" label="name" track-by="id">
                                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options selected</span></template>
                            </multiselect> -->
                            <multiselect v-model="selectedUser" tag-placeholder="Add this as new tag" :placeholder="$t('Search or add a tag')" label="name" track-by="id" :options="users" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                            <!-- <pre class="language-json"><code>{{ selectedUser  }}</code></pre> -->
                        <!-- <b-form-group :label="$t('user*')" label-for="name">
                            <b-form-select v-model="selectedUser" multiple>
                                <b-form-select-option v-for="user in users" :value="user.id" :key="user.id">{{user.name}}</b-form-select-option>
                            </b-form-select>
                            <b-form-invalid-feedback id="input-1-live-feedback">
                                {{ $t("field-required") }}
                            </b-form-invalid-feedback>
                        </b-form-group> -->
                    </div>
                    </div>
                    <div class="row" v-if="this.version_id === 2 || this.version_id === 3">
                        <div class="col-md-6">
                            <b-form-group :label="$t('Geo Marker Color')" id="input-3" label-for="input-3">
                                <b-form-select
                                id="input-3"
                                v-model="form.category_color"
                                :options="category_color.map(color => color.name)"
                                required
                                ></b-form-select>
                                <input type="hidden" v-model="categoryColorHash" />
                            </b-form-group>
                        </div>
                    </div>
                    <b-button type="submit" variant="success" :disabled="isDisabled" class="mr-2">
                        <span v-if="form.id == ''">{{ $t('submit-btn') }}</span>
                        <span v-else>{{ $t('update-btn') }}</span>
                    </b-button>
                </form>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex align-items-center question-order row-question-order">
                            <h4 class="card-title">{{ $t('categories') }}</h4>
                            <div class="header-buttons">
                                <button class="btn btn-primary" @click="showArchieved">{{ $t('Archived') }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading" @on-search="searchFn" :search-options="{enabled: true,searchFn: searchFn}"
                            :pagination-options="{
                                enabled: true,
                                dropdownAllowAll: false,
                                perPageDropdown: [10, 20, 50, 100, 200],
                            }" :rows="rows" :columns="tableColumns" :line-numbers="true">
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'action'" class="text-nowrap">
                                    <toggle-button
                                        v-model="categories.find(p => p.id === props.row.id).show"
                                        @change="changeStatus(props.row.id, $event)"
                                        :sync="true" class="mr-1 switchColor"/>
                                        <b-button size="sm" @click="editCategory(props.row.id)" class="mr-1 btn-info text-white">
                                            {{ $t('edit-btn') }}
                                        </b-button>
                                        <b-button size="sm" @click="deleteCategory(props.row.id)"
                                        class="mr-1 btn-danger text-white">
                                        {{ $t('delete-btn') }}
                                    </b-button>
                                    <toggle-button v-if="version_id === 2 && is_pro_sync === 1"
                                        v-model="categories.find(p => p.id === props.row.id).is_sync"
                                        @change="changeSync(props.row.id, $event)"
                                        :sync="true" class="mr-1 switchColor"/>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import Multiselect from 'vue-multiselect'
    import API from '@/api'
    import Snotify, {
        SnotifyPosition
    } from 'vue-snotify'
    import {
        validationMixin
    } from "vuelidate";
    import {
        required,
        minLength,
        email,
        requiredIf,
        sameAs
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    name: "",
                    id: "",
                    // selectedUser: "",
                    category_color: '',
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    show: true,
                    is_sync: true,
                    is_pro_sync: '',
                },
                category_color: [
                    { name: 'Red', hash: '#FF0000' },
                    { name: 'Blue', hash: '#0000FF' },
                    { name: 'Green', hash: '#00FF00' },
                    { name: 'Yellow', hash: '#FFFF00' },
                    { name: 'Orange', hash: '#FFA500' },
                    { name: 'Purple', hash: '#800080' },
                    { name: 'Pink', hash: '#FFC0CB' },
                    { name: 'Brown', hash: '#A52A2A' },
                    { name: 'Black', hash: '#000000' },
                    { name: 'White', hash: '#FFFFFF' },
                    { name: 'Gray', hash: '#808080' },
                    { name: 'Cyan', hash: '#00FFFF' },
                    { name: 'Magenta', hash: '#FF00FF' },
                    { name: 'Maroon', hash: '#800000' },
                    { name: 'Lime', hash: '#00FF00' },
                    { name: 'Navy', hash: '#000080' },
                    { name: 'Olive', hash: '#808000' },
                    { name: 'Teal', hash: '#008080' },
                    { name: 'Violet', hash: '#EE82EE' },
                    { name: 'Silver', hash: '#C0C0C0' }
                ],
                isDisabled: false,
                title: i18n.t('add-category'),
                serverParams: {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    columnFilters: {},
                    sort: [{
                        field: 'id',
                        type: 'desc'
                    }],
                    page: 1,
                    perPage: 10
                },
                columns: [{
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Geo Marker Color',
                        field: 'category_color',
                        sortable: true,
                    },
                    {
                        label: 'Created At',
                        field: 'created_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Updated At',
                        field: 'updated_at',
                        sortable: true,
                        formatFn: this.formatDate
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
                categories: [],
                users:[],
                selectedUser:[],
                value: null,
                version_id: null,
            };
        },
        validations: {
            form: {
                id: {},
                name: {
                    required,
                },
            }
        },
        methods: {
            showArchieved() {
                this.$router.push("/categories/archived", () => {});
            },
            changeStatus(id, event) {
                console.log(id, event.value);
                const data = {
                    id: id,
                    show: event.value === true ? 1 : 0
                };
                API.updateCategoryStatus(
                    data,
                    data => {
                        this.loadItems();
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {}
                );
            },
            changeSync(id, event) {
                console.log(id, event.value);
                const data = {
                    id: id,
                    is_sync: event.value === true ? 1 : 0
                };
                API.updateCategorySync(
                    data,
                    data => {
                        this.loadItems();
                        if (data.status === 200) {
                            this.$snotify.success(data.message);
                        }
                    },
                    err => {}
                );
            },
            deleteCategory(e) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        // let data = {
                        //     id: e
                        // }
                        API.deleteCategory(
                            e,
                            data => {
                                this.loadItems();
                                this.$snotify.success('Record has been deleted.');
                            },
                            err => {})
                    }
                })
            },
            editCategory(e) {
                this.$v.form.$reset();
                this.title = i18n.t('edit-category');
                this.$root.$emit("bv::show::modal", "modalmd");
                const selectedColor = this.category_color.find(color => color.name === this.form.category_color);
                API.getEditCategory(
                    e, data => {
                        this.form.name = data.name;
                        this.form.id = data.id;
                        this.selectedUser = data.selectedUser;
                        this.form.category_color = data.category_color;
                        this.hash_color = selectedColor ? selectedColor.hash : '' 
                        this.form.show = (data.data.show == 1) ? true : false;
                        this.form.is_sync = (data.data.is_sync == 1) ? true : false;
                    },
                    err => {}
                )
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                if (this.form.name != '') {
                    this.isDisabled = true;
                    const selectedColor = this.category_color.find(color => color.name === this.form.category_color);
                    const data = {
                        ...this.form,
                        selectedUser: this.selectedUser , 
                        hash_color: selectedColor ? selectedColor.hash : '' 
                    }
                    API.addEditCategories(
                        data,
                        data => {
                            this.$refs["vuemodal"].$refs['close-button'].click();
                            if (data.status === 200) {
                                this.$snotify.success(data.message);
                            } else {
                                this.$snotify.error(data.message);
                            }
                            this.loadItems();
                            this.isDisabled = false;
                            this.form.name = '';
                            this.form.id = '';
                            this.form.category_color = '';
                            this.form.selectedColorCode = ''
                            this.selectedUser = '';
                            this.form.show = true;
                            this.form.is_sync = true;
                            this.$v.form.$reset();
    
    
                        },
                        err => {
                            this.isDisabled = false;
                            this.$snotify.error(err, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
    
                        }
                    )
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.isDisabled = false;
                }
            },
            loadItems() {
                this.isLoading = true;
                API.getAllCategories(this.serverParams,
                    data => {
                        this.isLoading = false;
                        this.is_pro_sync = data.is_pro_sync;
                        this.version_id = data.version_id;
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;
                        this.rows.forEach(q => {
                            const data = {
                                id: q.id,
                                show: q.show === 1 ? true : false,
                                is_sync: q.is_sync === 1 ? true : false
    
                            };
                            this.categories.push(data);
                        });
                    },
                    err => {}
                )
            },
            resetForm() {
                this.title = i18n.t('add-category');
                this.form.name = '';
                this.form.id = '';
                this.form.category_color = '';
                this.selectedUser = '';
                this.$v.form.$reset();
                this.$root.$emit("bv::show::modal", "modalmd");
            },
            formatDate(value) {
                if (value) {
                    return moment(String(value)).format('YYYY-MM-DD')
                }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
    
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
    
            onPerPageChange(params) {
                this.updateParams({
                    perPage: params.currentPerPage
                });
                this.loadItems();
            },
    
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({
                    searchTerm: params
                });
                this.loadItems();
                this.isLoading = false;
            },
            formatStatus(status) {
                if (status === 1) {
                    return true;
                } else {
                    return false;
                }
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            getClientUsers() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                }
                API.getClientUsers(data,
                    data => {
                        this.users = data.data;
                        console.log('this.users :', this.users);
                    },
                    err => {}
                );
            },
        }, computed: {
            categoryColorHash() {
                const selectedColor = this.category_color.find(color => color.name === this.form.category_color);
                return selectedColor ? selectedColor.hash : '';
            },
            tableColumns() {
                let columns;
                if (this.version_id === 2 && this.is_pro_sync === 1) {
                    columns = [
                        {
                            label: 'Name',
                            field: 'name',
                            filterable: true
                        },
                        {
                            label: 'Geo Marker Color',
                            field: 'category_color',
                            sortable: true,
                        },
                        {
                            label: 'Created At',
                            field: 'created_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Updated At',
                            field: 'updated_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Show / Action / Sync',
                            field: "action",
                            sortable: false,
                        },
                    ];

                }
                else if(this.version_id === 3 || this.version_id === 2){
                    columns = [
                        {
                            label: 'Name',
                            field: 'name',
                            filterable: true
                        },
                        {
                            label: 'Geo Marker Color',
                            field: 'category_color',
                            sortable: true,
                        },
                        {
                            label: 'Created At',
                            field: 'created_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Updated At',
                            field: 'updated_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Show / Action',
                            field: "action",
                            sortable: false,
                        },
                    ];

                }
                else{
                    columns = [
                        {
                            label: 'Name',
                            field: 'name',
                            filterable: true
                        },
                        {
                            label: 'Created At',
                            field: 'created_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Updated At',
                            field: 'updated_at',
                            sortable: true,
                            formatFn: this.formatDate
                        },
                        {
                            label: 'Action',
                            field: "action",
                            sortable: false,
                        },
                    ];
                }

            return columns;
        }
        },
        mounted() {
            this.isLoading = true;
            this.loadItems();
            this.getClientUsers();
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .row {
        align-items: center;
    }
    @media (max-width: 1150px) {
        .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>